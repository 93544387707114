var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',[_c('v-list-item',{staticClass:"profile-item"},[_c('v-list-item-icon',{staticClass:"profile-item-avatar"},[_c('v-img',{attrs:{"src":_vm.profile.avatar,"height":"24","width":"24"}})],1),_c('v-list-item-content',{staticClass:"profile-item-content"},[_c('v-list-item-title',{staticClass:"profile-item-title body-m-medium neutral-700--text"},[_vm._v(" "+_vm._s(_vm.profile.UserName)+" ")])],1),_c('v-list-item-action-text',{staticClass:"body-s-semibold profile-item-action color-success",on:{"click":function($event){return _vm.$router.push({name: 'SettingsRequisites', hash: '#balance'})}}},[_c('span',{staticClass:"success--text"},[_vm._v(_vm._s(_vm.balance + ' ₽'))])])],1),_c('v-menu',{attrs:{"top":"","offset-x":"","offset-y":"","z-index":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list',[_c('v-list-item',_vm._g({},on),[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"neutral-500"}},[_vm._v(" mdi-translate ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"body-m-medium neutral-500--text",domProps:{"textContent":_vm._s(_vm.langEnum[_vm.$i18n.locale])}})],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary","size":"15"},domProps:{"textContent":_vm._s(_vm.expandLangList? 'fas fa-chevron-up': 'fas fa-chevron-down')}})],1)],1)],1)]}}]),model:{value:(_vm.expandLangList),callback:function ($$v) {_vm.expandLangList=$$v},expression:"expandLangList"}},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"300px"}},[_c('v-list-item-group',{attrs:{"value":Object.keys(_vm.langEnum).findIndex(function (l) { return l === _vm.$i18n.locale; }),"color":"primary"}},_vm._l((_vm.langEnum),function(label,key){return _c('v-list-item',{key:key,on:{"click":function($event){return _vm.changeLang(key)}}},[_c('v-list-item-title',{staticClass:"body-l-semibold",attrs:{"color":"neutral-900"}},[_vm._v(" "+_vm._s(label)+" ")])],1)}),1)],1)],1),_c('main-menu-item',{attrs:{"item":{
      icon: (_vm.internalMini ? 'mdi-arrow-right' :'mdi-arrow-left'),
      title: _vm.$t('turn_off'),
    },"mini":_vm.mini},on:{"click":function($event){_vm.internalMini = !_vm.internalMini}}}),_c('main-menu-item',{attrs:{"item":{
      title: _vm.$t('exit'),
      action_icon: 'mdi-help-circle-outline'
    },"mini":_vm.mini},on:{"click":_vm.exitClick,"action-click":function($event){return _vm.$router.open(_vm.$config.help.root)}},scopedSlots:_vm._u([{key:"prependIcon",fn:function(){return [_c('v-icon',{staticStyle:{"transform":"rotate(180deg)"},attrs:{"color":"neutral-500"}},[_vm._v(" $iconify_ion-exit-outline ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }