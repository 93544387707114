<template>
  <v-list>
    <v-list-item class="profile-item">
      <v-list-item-icon class="profile-item-avatar">
        <!-- :src="profile.avatar" -->
        <v-img
          :src="profile.avatar"
          height="24"
          width="24"
        />
      </v-list-item-icon>
      <v-list-item-content class="profile-item-content">
        <v-list-item-title
          class="profile-item-title body-m-medium neutral-700--text"
        >
          {{ profile.UserName }}
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-action-text
        class="body-s-semibold profile-item-action color-success"
        @click="$router.push({name: 'SettingsRequisites', hash: '#balance'})"
      >
        <span class="success--text">{{ balance + ' ₽' }}</span>
      </v-list-item-action-text>
    </v-list-item>

    <v-menu
      v-model="expandLangList"
      top
      offset-x
      offset-y
      z-index="500"
    >
      <template v-slot:activator="{ on}">
        <v-list>
          <v-list-item
            v-on="on"
          >
            <v-list-item-icon>
              <v-icon color="neutral-500">
                mdi-translate
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="body-m-medium neutral-500--text"
                v-text="langEnum[$i18n.locale]"
              />
            </v-list-item-content>

            <v-list-item-icon>
              <v-icon
                color="primary"
                size="15"
                v-text="expandLangList? 'fas fa-chevron-up': 'fas fa-chevron-down'"
              />
            </v-list-item-icon>
          </v-list-item>
        </v-list>
        <!-- <main-menu-item
          :item="{
            icon: null,
            title: langEnum[$i18n.locale],
          }"
          text
          :mini="mini"
          v-on="on"
        /> -->
      </template>
      <v-list
        class="overflow-y-auto"
        style="max-height: 300px"
      >
        <v-list-item-group
          :value="Object.keys(langEnum).findIndex(l => l === $i18n.locale)"
          color="primary"
        >
          <v-list-item
            v-for="(label, key) in langEnum"
            :key="key"
            @click="changeLang(key)"
          >
            <v-list-item-title
              class="body-l-semibold"
              color="neutral-900"
            >
              {{ label }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>

    <main-menu-item
      :item="{
        icon: (internalMini ? 'mdi-arrow-right' :'mdi-arrow-left'),
        title: $t('turn_off'),
      }"
      :mini="mini"
      @click="internalMini = !internalMini"
    />

    <main-menu-item
      :item="{
        title: $t('exit'),
        action_icon: 'mdi-help-circle-outline'
      }"
      :mini="mini"
      @click="exitClick"
      @action-click="$router.open($config.help.root)"
    >
      <template v-slot:prependIcon>
        <v-icon
          color="neutral-500"
          style="transform: rotate(180deg)"
        >
          $iconify_ion-exit-outline
        </v-icon>
      </template>
    </main-menu-item>
  </v-list>
</template>

<script>

  export default {
    name: 'AppMenuFooter',
    components: {
      MainMenuItem: () => import('./MainMenuItem'),
    },
    model: {
      prop: 'mini',
      event: 'change',
    },
    props: {
      mini: Boolean,
    },
    data: () => ({
      // balance: 9999,
      selectedItem: null,
      expandLangList: false,
      langEnum: {
        ru: 'Русский',
        en: 'English',
        tr: 'Türk',
      }
      ,
    }),
    computed: {
      balance () {
        return this.merchant ? this.merchant.balance_rub : '0'
      },
      profile () {
        return this.$store.getters['profile/profile/profile']
      },
      merchant () {
        return this.$store.getters.merchant
      },
      internalMini: {
        get () {
          return this.mini
        },
        set (val) {
          if (val === this.mini) return
          this.$emit('change', val)
        },
      },
    },
    mounted () {
      if (this.$session.has('i18n_locale')) {
        this.changeLang(this.$session.get('i18n_locale'))
      }
    },
    methods: {
      changeLang (lang) {
        this.$i18n.locale = lang
        this.$session.set('i18n_locale', lang)
      },
      exitClick () {
        this.exitRequest = true
        this.$store.dispatch('auth/auth/logout').finally(() => {
          this.exitRequest = false
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
@import "@/styles/_typography";

.profile-item-action {
  cursor: pointer;
}

#program-logo-btn {
  @include body-l-semibold;
  height: 36px;
  width: 36px;
  margin-left: -8px;
}

.program-select,
.main-logo-row {
  padding-left: 20px;
  padding-right: 20px;
}

.main-logo-row,
.main-logo-row-mini {
  margin-top: 34px;
  margin-bottom: 34px;
}

.main-logo-row-mini {
  padding-left: 14px;
  padding-right: 14px;
}

.profile-item {
  // min-height: 46px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 26px;

  .profile-item-avatar {
    margin: 12px 0 12px -1px;
    border-radius: 100px;
  }

  .profile-item-content {
    margin-left: 19px;
  }
}
</style>
